import { Disbursement, EnrollmentPeriod, RequestFunds, School } from "@/models";
import { Module } from "vuex";
import { $api } from "@/services/api";
import { CreateDrawRequest } from "@/models/api";
import { Option } from "@/models/form";

const fundsModule: Module<RequestFunds, any> = {
  namespaced: true,
  state: {
    anticipationGraduation: undefined,
    requestedAmount: "",
    disbursement: [],
    schoolLevels: [],
    school: {
      schoolBranch: "",
      schoolCode: "",
      schoolId: 0,
      schoolLevelTypeId: 0,
      schoolName: "",
    },
    fundingTerm: {
      description: "",
      id: 0,
      name: "",
      starDate: "",
      endDate: "",
      visibleStartDate: "",
      visibleEndDate: "",
    },
    errorMessage: "",
    states: [],
    schools: [],
    enrollmentPeriods: [],
    enrollmentStatus: 0,
    enrollmentStatuses: [
      { label: "Full-Time", value: 1 },
      { label: "Half-Time", value: 2 },
      { label: "Less Than Half-Time", value: 3 },
    ],
  },
  getters: {
    DrawRequest: (state: RequestFunds): CreateDrawRequest => {
      const drawRequest: CreateDrawRequest = {
        loanId: 0,
        schoolId: state.school.schoolId,
        schoolBranch: state.school.schoolBranch,
        schoolCode: state.school.schoolCode,
        schoolLevelTypeId: state.school.schoolLevelTypeId,
        enrollmentTermId: state.fundingTerm.id,
        requestedAmount: state.requestedAmount,
        anticipatedGraduationDate: state.anticipationGraduation ?? new Date(),
        enrollmentStatus: state.enrollmentStatus || 0,
      };
      return drawRequest;
    },
    DISBURSEMENTS: (state: RequestFunds): Array<Disbursement> => {
      return state.disbursement;
    },
    SchoolName: (state: RequestFunds): string => {
      return state.school?.schoolName ?? "";
    },
    GraduationMonth: (state: RequestFunds): number => {
      const monthIndex = state.anticipationGraduation?.getMonth() ?? 0;
      if (state.anticipationGraduation) {
        return monthIndex + 1;
      }

      return -1;
    },
    GraduationYear: (state: RequestFunds): number => {
      const year = state.anticipationGraduation?.getFullYear() ?? 0;

      return year;
    },
    AcademicPeriodId: (state: RequestFunds): number => {
      return state.fundingTerm.id;
    },
    AcademicPeriod: (state: RequestFunds): string => {
      return state.fundingTerm?.description;
    },
    AmountRequested: (state: RequestFunds): string => {
      return state.requestedAmount;
    },
    GetErrorMessage: (state: RequestFunds): string => {
      return state.errorMessage;
    },
    EnrollmentStatusId: (state: RequestFunds): any => {
      return state.enrollmentStatus;
    },
    EnrollmentStatusName: (state: RequestFunds): any => {
      return (
        state.enrollmentStatuses?.find((o: Option) => o.value === state.enrollmentStatus)?.label || "Not Yet Selected"
      );
    },
  },
  mutations: {
    setErrorMessage(state: RequestFunds, message: string) {
      state.errorMessage = message;
    },
    setGraduationDate(state: RequestFunds, graduationDate: Date) {
      state.anticipationGraduation = graduationDate;
    },
    setRequestAmount(state: RequestFunds, requestAmount: string) {
      state.requestedAmount = requestAmount;
    },
    resetAllStates(state: RequestFunds) {
      state.disbursement = [];
      state.errorMessage = "";
      state.requestedAmount = "";
      state.anticipationGraduation = undefined;
      state.school = {
        schoolBranch: "",
        schoolCode: "",
        schoolId: 0,
        schoolLevelTypeId: 0,
        schoolName: "",
      };
      state.fundingTerm = {
        description: "",
        id: 0,
        name: "",
        starDate: "",
        endDate: "",
        visibleStartDate: "",
        visibleEndDate: "",
      };
      state.enrollmentStatus = 0;
    },
    resetDisbursement(state: RequestFunds) {
      state.disbursement = [];
    },
    setDisbursement(state: RequestFunds, disbursement: Disbursement) {
      state.disbursement.push(disbursement);
    },
    setFundingTerm(state: RequestFunds, fundingTerm: EnrollmentPeriod) {
      state.fundingTerm = fundingTerm;
    },
    setEnrollments(state: RequestFunds, enrollmentPeriods: []) {
      state.enrollmentPeriods = enrollmentPeriods;
    },
    setSchools(state: RequestFunds, schools: []) {
      state.schools = schools;
    },
    setSchoolLevels(state: RequestFunds, schoolLevels: []) {
      state.schoolLevels = schoolLevels;
    },
    setStates(state: RequestFunds, states: []) {
      state.states = states;
    },
    setSchool(state: RequestFunds, school: School) {
      state.school = school;
    },
    setEnrollmentStatus(state: RequestFunds, enrollmentStatusId: number) {
      state.enrollmentStatus = enrollmentStatusId;
    },
  },
  actions: {
    async fetchEnrollmentPeriods({ commit }) {
      try {
        const enrollments = await $api.originator.getEnrollments();

        if (enrollments) {
          commit("setEnrollments", enrollments);
        }
      } catch (error) {
        console.log(error);

        commit("setErrorMessage", error);
      }
    },
    async fetchSchoolLevels({ commit }, programTypeId: number) {
      try {
        const schoolLevels = await $api.originator.getSchoolLevels(programTypeId);

        if (schoolLevels.success) {
          commit("setSchoolLevels", schoolLevels.results);
        }
      } catch (error) {
        commit("setErrorMessage", error);
      }
    },
    async fetchSchoolsAndStates({ commit }, payload: any) {
      try {
        const [stateResult, schoolResult] = await Promise.all([
          $api.originator.getStates(),
          $api.originator.getSchools(payload?.programTypeId, payload?.productTypeId, payload?.lenderId),
        ]);

        if (stateResult.error) {
          commit("setErrorMessage", stateResult.message);
          return;
        }

        if (schoolResult.error) {
          commit("setErrorMessage", schoolResult.message);
          return;
        }

        //If any error found we wont update the schools and states..
        if (schoolResult) {
          commit("setSchools", schoolResult);
        }

        if (stateResult.success) {
          commit("setStates", stateResult.results);
        }

        commit("setErrorMessage", "");
      } catch (error) {
        commit("setErrorMessage", error);
      }
    },
  },
};

export default fundsModule;
