import { createStore } from "vuex";
import { VuexOidcClientSettings, vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings, getEnvironment } from "@/config/oidc";
import { WebStorageStateStore } from "oidc-client";
import {
    Loan,
    LenderData,
    BorrowerData,
    Configuration,
    FeatureFlags,
    Profile,
    BorrowerEmailPrompt
} from "@/models";
import storePlugins from "@/plugins/storePlugins";
import useSignOut from "../use/signout";
import fundsModule from "./fundsModule";

const environment = getEnvironment();

// Check if LID is in the URL
const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

if (params?.LID || params?.lid) {
    (oidcSettings as any).extraQueryParams = {
        lid: params?.LID || params?.lid,
    };
}

export default createStore({
    plugins: [storePlugins],
    state: {
        validSession: true,
        configuration: {
            cdnUrl: "https://cusc-lz01-nonprod-cdn.azureedge.net",
            releaseNumber: 504,
        } as Configuration,
        IsPaymentOverDue: false,
        hasOpalLoan: true,
        pastDueLoans: undefined,
        paymentPastDueAmount: undefined,
        notifications: undefined,
        borrowerData: undefined,
        lenderData: undefined,
        featureFlags: undefined,
        profile: undefined,
        borrowerEmailPrompt: undefined,
    },
    mutations: {
        SET_OPAL_LOAN_FLAG(state: any, containsOpalLoan: boolean) {
            state.hasOpalLoan = containsOpalLoan;
        },
        SET_PAST_DUE_LOANS(state: any, pastDueLoans: Array<Loan>) {
            state.pastDueLoans = pastDueLoans;
        },
        SET_IS_PAST_DUE(state: any, IsOverDue: boolean) {
            state.IsPaymentOverDue = IsOverDue;
        },
        SET_NOTIFICATIONS(state: any, notifications: Notification[]) {
            state.notifications = notifications;
        },
        SET_LENDER_DATA(state: any, lenderData: LenderData) {
            // Determine if page should follow light or dark theme
            if (lenderData.primaryColor) {
                const parsedHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
                    lenderData.primaryColor
                );
                const rgb = parsedHex
                    ? {
                        r: parseInt(parsedHex[1], 16),
                        g: parseInt(parsedHex[2], 16),
                        b: parseInt(parsedHex[3], 16),
                    }
                    : null;

                if (rgb !== null) {
                    // determine if light or dark theme is needed
                    const luminance = Math.round(
                        (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
                    );

                    // Adding else statement just in case client changes. Mainly for testing...
                    if (luminance > 118) {
                        document.body.classList.add("cuscd-dark");
                    } else {
                        document.body.classList.remove("cuscd-dark");
                    }
                }
            }

            state.lenderData = lenderData;
        },
        SET_BORROWER_DATA(state: any, borrowerData: BorrowerData) {
            state.borrowerData = borrowerData;
        },
        SET_CONFIGURATION(state: any, configuration: Configuration) {
            state.configuration = configuration;
        },
        SET_FEATURE_FLAGS(state: any, featureFlags: FeatureFlags) {
            state.featureFlags = featureFlags;
        },
        SET_PROFILE(state: any, profile: Profile) {
            state.profile = profile;
        },
        SET_BORROWER_EMAIL_PROMPT(
            state: any,
            borrowerEmailPrompt: BorrowerEmailPrompt
        ) {
            state.borrowerEmailPrompt = borrowerEmailPrompt;
        },
        SET_VALID_SESSION(state: any, validSession: boolean) {
            state.validSession = validSession;
        },
    },
    actions: {
        setOpalLoanFlag({ commit }, payload) {
            commit("SET_OPAL_LOAN_FLAG", payload);
        },
        setPastDueLoans({ commit }, payload) {
            commit("SET_PAST_DUE_LOANS", payload);
        },
        setIsPastDuePayment({ commit }, payload) {
            commit("SET_IS_PAST_DUE", payload);
        },
        setNotifications({ commit }, payload) {
            commit("SET_NOTIFICATIONS", payload);
        },
        setLenderData({ commit }, payload) {
            commit("SET_LENDER_DATA", payload);
        },
        setDefaultLender({ commit }) {
            commit("SET_LENDER_DATA", {
                lenderWebsiteUrl: "https://studentchoice.org",
                lenderSubdomain: "cusc",
                originatorLoginUrl: "https://studentchoice.org",
                lenderOffersRefinance: false,
                refinanceUrl: "https://studentchoice.org",
                applyUrl: "https://studentchoice.org",
                inSchoolTollFreeNumber: "800-734-4540",
                refiTollFreeNumber: "800-734-4540",
                primaryColor: "#f7941e",
                secondaryColor: "#096fb1",
                redirectOnLogout: false,
                postLogoutRedirectUri: "",
            });
        },
        setBorrowerData({ commit }, payload) {
            commit("SET_BORROWER_DATA", payload);
        },
        setFeatureFlags({ commit }, payload) {
            commit("SET_FEATURE_FLAGS", payload);
        },
        setProfile({ commit }, payload) {
            commit("SET_PROFILE", payload);
        },
        setBorrowerEmailPrompt({ commit }, payload) {
            commit("SET_BORROWER_EMAIL_PROMPT", payload);
        },
        setValidSession({ commit }, payload) {
            commit("SET_VALID_SESSION", payload);
        },
    },
    getters: {
        cuLogoUrl: (state): string => {
            return state.lenderData
                ? new URL(
                    `/public/assets/${state.lenderData?.lenderSubdomain || "cusc"
                    }/logo.png`,
                    state.configuration.cdnUrl
                ).toString()
                : "";
        },
        getCdnImagesUrl: (state: any) => (): string => {
            const path = `/public/Dev/lending-center/${state.configuration.releaseNumber}/images`;
            return new URL(path, state.configuration.cdnUrl).toString();
        },
        notificationCount: (state) => (): number => {
            const zero = 0;
            const countPaymentOverDue = state.pastDueLoans?.length || zero;
            const notificationCount = state.notifications?.length || zero;
            return notificationCount + countPaymentOverDue;
        },
    },
    modules: {
        fundsModule,
        oidcStore: vuexOidcCreateStoreModule(
            {
                ...environment,
                ...oidcSettings,
                userStore: new WebStorageStateStore({
                    store: window.sessionStorage,
                }),
            } as VuexOidcClientSettings,
            // Optional OIDC store settings
            {
                namespaced: true,
                dispatchEventsOnWindow: true,
            },
            // Optional OIDC event listeners
            {
                userLoaded: (user) => {
                    console.log("OIDC user is loaded:", user);
                },
                userUnloaded: () => console.log("OIDC user is unloaded"),
                accessTokenExpiring: () => console.log("Access token will expire"),
                accessTokenExpired: () => {
                    console.log("Access token expired.");
                    const { signOut } = useSignOut();
                    signOut();
                },
                silentRenewError: () => console.log("OIDC user is unloaded"),
                userSignedOut: () => {
                    console.log("User signed out.");
                    const { signOut } = useSignOut();
                    signOut();
                },
                oidcError: (payload) => {
                    console.log("OIDCError expired.");
                    const { signOut } = useSignOut();
                    signOut();
                },
                automaticSilentRenewError: (payload) =>
                    console.log("OIDC automaticSilentRenewError", payload),
            }
        ),
    },
});