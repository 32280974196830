import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import IdleVue from "idle-vue";
import VueGtag from "vue-gtag";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import { getEnvironment } from "./config/oidc";

axios.interceptors.request.use(
  (config: any) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${store.getters["oidcStore/oidcAccessToken"]}`,
      },
    };
  },
  (error: any) => Promise.reject(error)
);

// Gather all Base component names to auto-import
const requireComponent = require.context(
  "./components/shared",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const app = createApp(App);

// Here we standardize file neams to get component name then auto-import into app
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );

  // Register as a global component
  app.component(componentName, componentConfig.default || componentConfig);
});

const environment = getEnvironment();

app
  .use(store)
  .use(router)
  .use(IdleVue, {
    store,
    idleTime: 300000, // 5 minutes until idle alert
    startAtIdle: false,
  })
  .use(VueGtag, {
    config: { id: environment.googleAnalyticsId },
    router,
    enabled: true,
    params: {
      send_page_view: false,
    },
  });

app.provide("gtag", app.config.globalProperties.$gtag);

app.mount("#app");
