import { NotificationGroup } from "../models";
import { PostFeedbackRequest, SubmitProfileRequest, CreateDrawRequest } from "../models/api";
import axios from "axios";

class BaseApiService {
  // Since .NET us handling the routing, we don't need to include baseUrl
  // All API calls in controllers will start with {api}
  baseUrl = "api";
  resource: string;

  constructor(resource: string) {
    if (!resource) throw new Error("Resource is not provided");
    this.resource = resource;
  }

  getUrl(id = "") {
    return `${this.baseUrl}/${this.resource}/${id}`;
  }

  handleErrors(err: any): Promise<any> {
    // Note: here you may want to add your errors handling
    console.log("ERROR", { err });
    if (err.response?.data?.redirectUrl && typeof err.response.data.redirectUrl === "string") {
      console.log("redirecting to " + err.response.data.redirectUrl)
      sessionStorage.clear();
      window.location.href = err.response.data.redirectUrl;
      return Promise.resolve();
    } else if (err.response?.data && typeof err.response.data === "object") {
      // add error:true at the end in case message is used elsewhere
      return Promise.resolve({ ...err.response.data, error: true });
    } else {
      return Promise.resolve({ ...err, error: true });
    }
  }
}

class OriginatorApiService extends BaseApiService
{
  constructor() {
    super("originators");
  }

  async getStates() {
    try {
      const response = await axios(
        this.getUrl("state-info")
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err).catch(error => console.log(error));
    }
  }

  async getEnrollments() 
  {
    try {
      const response = await axios(
        this.getUrl("enrollment-info")
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err).catch(error => console.log(error));
    }

  }

  async getSchoolLevels(programTypeId: number) {
    try {
      const response = await axios(
        this.getUrl(`schoolLevels/${programTypeId}`)
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err).catch(error => console.log(error));
    }
  }

  async getSchools(programTypeId: number, productTypeId: number, lenderId: number) {
		try {
      const url = this.getUrl(`schools/program/${encodeURIComponent(programTypeId)}`);
      const response = await axios.get(url, {
        params: {
          productTypeId: productTypeId,
          lenderId: lenderId,
        },
      });

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async postDrawRequest(referenceId: any, body: CreateDrawRequest): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`${referenceId}/Draws/Create`), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

}

class SummaryApiService extends BaseApiService {
  constructor() {
    super("summary");
  }
  async get() {
    try {
      return await axios(this.getUrl());
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSummary(refresh = false, lenderId?: string): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(
          `get?refresh=${refresh}${lenderId ? "&lenderId=" + lenderId : ""}`
        )
      );
      return response.data || {};
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getNotifications(lenderId?: string): Promise<NotificationGroup[]> {
    try {
      const response = await axios(
        this.getUrl(`notifications${lenderId ? "?lenderId=" + lenderId : ""}`)
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async sendFeedback(body: PostFeedbackRequest) {
    try {
      const response = await axios.post(this.getUrl("feedback"), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getCurrentPayoff(uasLoanId?: string): Promise<any> {
    try {
      const response = await axios.get(
        this.getUrl(`payoff${uasLoanId ? "?loanId=" + uasLoanId : ""}`)
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getPaymentHistoryIndexAsync(months?: number): Promise<any> {
    try {
      const response = await axios.get(
        this.getUrl(`months${months ? "?months=" + months : ""}`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async getBorrowerContactInfoAsync(borrower_id?: string): Promise<any> {
    try {
      const response = await axios.get(
        this.getUrl(`${borrower_id ? borrower_id : ""}/info`)
      );
      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ProfileApiService extends BaseApiService {
  constructor() {
    super("profile");
  }
  async getProfile(): Promise<any> {
    try {
      const response = await axios(this.getUrl());

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async submitProfile(body: SubmitProfileRequest): Promise<any> {
    try {
      const response = await axios.put(this.getUrl(), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async updateUserEmails(body: any): Promise<any> {
    try {
      const response = await axios.put(this.getUrl(`update/emails`), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class RedirectApiService extends BaseApiService {
  constructor() {
    super("redirect");
  }
  async getUASRedirect(page?: string): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`uas${page ? "?page=" + page : ""}`)
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err).catch(error => console.log(error));
    }
  }
}

class AccountApiService extends BaseApiService {
  constructor() {
    super("account");
  }
  async verifyInviteToken(token: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`verify?inviteToken=${token}`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async registerUser(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`register`), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async verifyAccount(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`verify/account`), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async signout(unifiId: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`signout/${unifiId}`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class InviteApiService extends BaseApiService {
  constructor() {
    super("invite");
  }
  async checkInvite(token: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`check?token=${token}`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async resendInvite(token: string): Promise<any> {
    try {
      const response = await axios(this.getUrl(`resend?token=${token}`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async acceptInvite(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`accept`), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class CdhApiService extends BaseApiService {
  constructor() {
    super("cdh");
  }
  async getTransaction(loanId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`loan/${loanId}`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getDraw(loanId: number, drawId: string): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`loan/${loanId}/draw/${drawId}`)
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getStates(loanId: number): Promise<any> {
    try {
      const response = await axios(this.getUrl(`loan/${loanId}/states`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSchools(loanId: number, stateCode: string): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(`loan/${loanId}/states/${stateCode}/schools`)
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getSchoolBranchess(
    loanId: number,
    stateCode: string,
    schoolCode: string
  ): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(
          `loan/${loanId}/states/${stateCode}/schools/${schoolCode}/branches`
        )
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getAcademicYears(
    loanId: number,
    schoolCode: string,
    branchCode: string
  ): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(
          `loan/${loanId}/schools/${schoolCode}/branches/${branchCode}/academicyears`
        )
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getEnrollmentPeriods(
    loanId: number,
    schoolCode: string,
    branchCode: string
  ): Promise<any> {
    try {
      const response = await axios(
        this.getUrl(
          `loan/${loanId}/schools/${schoolCode}/branches/${branchCode}/enrollmentperiods`
        )
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async postDrawRequest(body: any): Promise<any> {
    try {
      const response = await axios.post(this.getUrl(`draw`), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class ConfigurationApiService extends BaseApiService {
  constructor() {
    super("configuration");
  }
  async getLenderConfig(): Promise<any> {
    try {
      const response = await axios(this.getUrl(`lender`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }

  async getBorrowerConfig(): Promise<any> {
    try {
      const response = await axios(this.getUrl(`borrower`));

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class DocumentsApiService extends BaseApiService {
  constructor() {
    super("documents");
  }

  async pullDocuments(body: any) {
    try {
      const response = await axios.post(this.getUrl(), body);

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}

class PromptApiService extends BaseApiService {
  constructor() {
    super("prompt");
  }
  async evaluateBorrowerEmailAsync(currentEmail: any): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`borrowerContact/${currentEmail}/user/evaluate`),
        currentEmail
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
  async setBorrowerSelectionAsync(request: any): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`borrowerContact/user/update`),
        request
      );

      return response.data;
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}
class TelemetryApiService extends BaseApiService {
  constructor() {
    super("telemetry");
  }
  async setRefinanceConsent(data: any): Promise<any> {
    try {
      const response = await axios.post(
        this.getUrl(`REFI_COMMUNICATION_CONSENT`),
        data
      );

      return response.data || [];
    } catch (err) {
      return this.handleErrors(err);
    }
  }
}
export const $api = {
  configuration: new ConfigurationApiService(),
  originator: new OriginatorApiService(),
  summary: new SummaryApiService(),
  profile: new ProfileApiService(),
  redirect: new RedirectApiService(),
  account: new AccountApiService(),
  invite: new InviteApiService(),
  cdh: new CdhApiService(),
  documents: new DocumentsApiService(),
  prompt: new PromptApiService(),
  telemetry: new TelemetryApiService()
};
