export const oidcSettings = {
  responseType: "id_token token",
  scope: "openid profile",
  automaticSilentRenew: true,
  automaticSilentSignin: true
};

export enum EnvType {
  local = "LOCAL",
  dev = "DEV",
  qa = "QA",
  uat = "UAT",
  prod = "PROD",
  localci = "LOCALCI",
  localuat = "LOCALUAT"
}

export const oidcEnvironments = {
  local: {
    env: EnvType.local,
    authority: "https://localhost:44351",
    clientId: "StudentLendingCenter",
    redirectUri: "https://localhost:44372/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV
  },
  localci: {
    env: EnvType.localci,
    authority: "https://identity-dev.studentchoice.org",
    clientId: "StudentLendingCenter",
    redirectUri: "https://localhost:44371/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV
  },
  localuat: {
    env: EnvType.localuat,
    authority: "https://identity-uat.studentchoice.org",
    clientId: "StudentLendingCenter",
    redirectUri: "https://localhost:44374/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV
  },
  dev: {
    env: EnvType.dev,
    authority: "https://identity-dev.studentchoice.org",
    clientId: "StudentLendingCenter",
    redirectUri: "https://dashboard-dev.studentchoice.org/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV
  },
  qa: {
    env: EnvType.qa,
    authority: "https://identity-qa.studentchoice.org",
    clientId: "StudentLendingCenter",
    redirectUri: "https://dashboard-qa.studentchoice.org/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD
  },
  uat: {
    env: EnvType.uat,
    authority: "https://identity-uat.studentchoice.org",
    clientId: "StudentLendingCenter",
    redirectUri: "https://lendingcenter-uat.studentchoice.org/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV
  },
  prod: {
    env: EnvType.prod,
    authority: "https://identity.studentchoice.org",
    clientId: "StudentLendingCenter",
    redirectUri: "https://lendingcenter.studentchoice.org/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD
  }
};

export function getEnvironment() {
  // default to PROD
  let environment = oidcEnvironments.prod;
  const host = window.location.hostname;
  const port = window.location.port;

  if (host.includes("localhost") && port === "44372") {
    environment = oidcEnvironments.local;
  } else if (host.includes("localhost") && port === "44371") {
    environment = oidcEnvironments.localci;
  } else if (host.includes("localhost") && port === "44374") {
    environment = oidcEnvironments.localuat;
  } else if (host.includes("-dev")) {
    environment = oidcEnvironments.dev;
  } else if (host.includes("-qa")) {
    environment = oidcEnvironments.qa;
  } else if (host.includes("-uat")) {
    environment = oidcEnvironments.uat;
  }

  // disable logging for UAT and PROD environments
  // if (environment.env === EnvType.uat || environment.env === EnvType.prod) {
    if (environment.env === EnvType.prod) {
    console.log = () => {};
    console.warn = () => {};
    console.debug = () => {};
    console.info = () => {};
  }

  return environment;
}
