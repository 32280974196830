import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import OidcCallback from "../views/OidcCallback.vue";
import OidcCallbackError from "../views/OidcCallbackError.vue";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "../store";

import ReCaptchaCheckbox from "../views/ReCaptchaCheckbox.vue";
import ReCaptchaInvisible from "../views/ReCaptchaInvisible.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/summary"
  },
  {
    path: "/summary",
    name: "summary",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Summary.vue"),
    meta: {
      title: "Summary"
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/MyProfile.vue"),
    meta: {
      title: "Profile"
    }
  },
  {
    path: "/documents",
    name: "documents",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Documents.vue"),
    meta: {
      title: "Documents"
    }
  },
  {
    path: "/accept",
    name: "accept",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/AcceptInvite.vue"),
    meta: {
      isPublic: true,
      title: "Accept Invite"
    }
  },
  {
    path: "/verify",
    name: "verify",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/VerifyAccount.vue"),
    meta: {
      isPublic: true,
      title: "Verify Account"
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Register.vue"),
    meta: {
      isPublic: true,
      title: "Register"
    }
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Terms.vue"),
    meta: {
      isPublic: true,
      title: "Terms of Use"
    }
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/PrivacyPolicy.vue"),
    meta: {
      isPublic: true,
      title: "Privacy Policy"
    }
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Accessibility.vue"),
    meta: {
      isPublic: true,
      title: "Accessibility"
    }
  },
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Support.vue"),
    meta: {
      isPublic: true,
      title: "Support"
    }
  },
  {
    path: "/loggedout",
    name: "loggedout",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/LoggedOut.vue"),
    meta: {
      isPublic: true,
      title: "Logged Out"
    }
  },
  {
    path: "/oidc-callback", // Needs to match redirectUri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isPublic: true,
      title: "Redirecting..."
    }
  },
  {
    path: "/oidc-callback-error", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallbackError",
    component: OidcCallbackError,
    meta: {
      isPublic: true,
      title: "Error"
    }
  },
  {
    path: "/recaptcha-checkbox",
    name: "recaptcha-checkbox",
    component: ReCaptchaCheckbox,
    meta: {
      isPublic: true,
      title: "reCAPTCHA Checkbox"
    }
  },
  {
    path: "/recaptcha-invisible",
    name: "recaptcha-invisible",
    component: ReCaptchaInvisible,
    meta: {
      isPublic: true,
      title: "reCAPTCHA Invisible"
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    const app = document.getElementById("app");
    if (app) {
      app.scrollIntoView();
    }
  }
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;
