<template>
  <div>
    <h1>Error in authentication</h1>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "OidcCallbackError"
});
</script>
