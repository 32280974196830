<template>
  <div v-show="show" ref="recaptcha"></div>
</template>

<script>
// Initial implementation taken from, https://www.npmjs.com/package/vue3-recaptcha2
// Installing the NPM package required special typing. By importing this way
// it gives us more freedom to fit to our needs. Also had some Typescript issues
// when trying to import via NPM...
import { defineComponent } from "vue";

export default defineComponent({
  name: "vueRecaptcha",
  data() {
    return {
      recaptcha: null
    };
  },
  props: {
    siteKey: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: "normal"
    },
    theme: {
      type: String,
      required: false,
      default: "light"
    },
    show: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: {
    verify: response => {
      if (response) return true;
      else return false;
    },
    expire: null,
    fail: null
  },
  methods: {
    renderRecaptcha() {
      // eslint-disable-next-line no-undef
      this.recaptcha = grecaptcha.render(this.$refs.recaptcha, {
        sitekey: this.siteKey,
        theme: this.theme,
        size: this.size,
        tabindex: this.tabindex,
        callback: response => this.$emit("verify", response),
        "expired-callback": () => this.$emit("expire"),
        "error-callback": () => this.$emit("fail")
      });
    },
    reset() {
      // eslint-disable-next-line no-undef
      grecaptcha.reset(this.recaptcha);
    },
    execute() {
      // eslint-disable-next-line no-undef
      grecaptcha.execute(this.recaptcha);
    }
  },
  mounted() {
    if (window.grecaptcha == null) {
      new Promise(resolve => {
        window.recaptchaReady = function() {
          resolve();
        };

        const doc = window.document;
        const scriptId = "recaptcha-script";
        const scriptTag = doc.createElement("script");
        scriptTag.id = scriptId;
        scriptTag.setAttribute(
          "src",
          "https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit"
        );
        doc.head.appendChild(scriptTag);
      }).then(() => {
        this.renderRecaptcha();
      });
    } else {
      this.renderRecaptcha();
    }
  }
});
</script>
