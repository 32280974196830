/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FormField, FormSchema } from "../models/form";

export default function useValidation() {
  function getErrorMessage(field: FormField) {
    if (field.errorMessage) {
      return field.errorMessage;
    }

    switch (field.type) {
      case "email":
        return "Please provide a valid email address.";
      case "phone":
        return "Please provide a valid phone number.";
      case "confirm-password":
        return "Passwords do not match.";
      default:
        return field.label
          ? `${field.label} is required.`
          : "Please provide a value.";
    }
  }

  async function validate(
    schema: FormField,
    value: any,
    compare: any = undefined
  ) {
    schema.touched = true;
    // Reset the error messages
    schema.errors = [];

    if (schema.skipValidation) {
      return;
    }

    // If schema has custom validation, let them, handle it
    if (schema.validate) {
      schema.validate(schema, value, compare);
    } else {
      if (schema.required) {
        const trimmedValue =
          typeof value === "string" ? value.replace(/\s/g, "") : value;
        // Check if the field is requried first
        schema.valid =
          trimmedValue !== undefined &&
          trimmedValue !== "" &&
          trimmedValue !== null;
      } else {
        schema.valid = true;
      }

      if (
        value === undefined ||
        value === "" ||
        value === null ||
        value === 0
      ) {
        // If empty we tell it to return the opposite of what is allowed
        schema.valid = !schema.required;
      } else {
        if (schema.type === "email") {
          // Check if the field needs email validation
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          schema.valid = re.test(value);
        } else if (schema.type === "phone") {
          // Check if the field needs phone number validation
          const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          schema.valid = re.test(value);
        } else if (schema.type === "password") {
          // Test each rule individually for better readability

          // Adding the undefined checks because for some reason not all
          // rules would get hit if value was undefined...

          // At least 8 characters long and no more than 32 characters
          if (value === undefined || value.length < 8 || value.length > 32) {
            schema.errors.push("Must be between 8 and 32 characters.");
          }

          // Uppercase Alphabet.
          if (value === undefined || /[A-Z]/.test(value) === false) {
            schema.errors.push("Must contain an uppercase letter.");
          }

          // Lowercase Alphabet.
          if (value === undefined || /[a-z]/.test(value) === false) {
            schema.errors.push("Must contain a lowercase letter.");
          }

          // At least one number.
          if (value === undefined || /[0-9]/.test(value) === false) {
            schema.errors.push("Must contain a number.");
          }

          //Special Character.
          if (value === undefined || !new RegExp("[$@$!%*#?&-]").test(value)) {
            schema.errors.push("Must contain a special character.");
          }

          // Passed all 5 password rules
          schema.valid = schema.errors.length <= 0;
        } else if (schema.type === "confirm-password") {
          schema.valid =
            compare !== undefined &&
            compare !== "" &&
            compare !== null &&
            value === compare;
        } else if (schema.type === "checkbox") {
          schema.valid = value === true || schema.required === false;
        }
      }

      if (schema.valid === false && schema.errors.length <= 0) {
        schema.errors = [getErrorMessage(schema)];
      }
    }
  }

  function validateForm(schema: FormSchema, formValue: any): boolean {
    let valid = true;

    for (const [key, value] of Object.entries(schema)) {
      if ((value as any).type === "confirm-password") {
        validate(value as FormField, formValue[key], formValue.password);
      } else {
        validate(value as FormField, formValue[key]);
      }
      valid = valid && (value as any).valid;
    }

    return valid;
  }

  function clearValidation(schema: FormSchema) {
    for (const [key, value] of Object.entries(schema)) {
      (value as FormField).valid = false;
      (value as FormField).touched = false;
      (value as FormField).errors = [];
    }
  }

  return { validate, validateForm, clearValidation };
}
